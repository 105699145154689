<template>
  <div class="homepage">
    <div class="headerBox">
      <div class="header">
        <div class="h-image">
          <img src="../assets/logo.png" />
        </div>
        <div class="menu">
          <ul class="menu-list">
            <li
              :class="chooseOne === '/' ? 'chooseOne' : ''"
              @click="togo('/')"
            >
              首页<br />
            </li>
            <li
              :class="chooseOne === '/cooperation' ? 'chooseOne' : ''"
              @click="togo('/cooperation')"
            >
              金树丫合作<br />
            </li>
            <li
              :class="chooseOne === '/about' ? 'chooseOne' : ''"
              @click="togo('/about')"
            >
              关于我们<br />
            </li>
            <li
              :class="chooseOne === '/askJoin' ? 'chooseOne' : ''"
              @click="togo('/askJoin')"
            >
              幼儿园入驻<br />
            </li>
            <li
              :class="chooseOne === '/download' ? 'chooseOne' : ''"
              @click="togo('/download')"
            >
              下载APP<br />
            </li>
          </ul>
          <div class="rightBtn" style="margin-left: 15px" @click="toSchool">
            教师登录
          </div>
          <div class="rightBtn" @click="toAgent">代理商登录</div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 69px"></div>
    <router-view></router-view>
    <div class="footer-info">
      <!-- <a href="https://beian.miit.gov.cn" target="_blank"
        >Copyright © 2019-2022 成都在线支点教育科技有限公司 All Rights Reserved.
        蜀ICP备20023331号-3</a
      > -->
      <div class="footer-title">
        <span class="footer-title1">关于金树丫</span>
        <span class="footer-title2">产品功能</span>
        <span class="footer-title3">公司地址</span>
      </div>
      <div class="footer-content">
        <div class="footer-content1">
          <p>
            金树丫是成都非络通达科技有限公司旗下专注于幼教行业
            的品牌，致力打造智慧家园共育系统。为家长、园区和第
            三方幼教机构提供一个科学、高效的家校共育互动平台，
            让老师和家长共同记录孩子的成长每一天。
          </p>
        </div>
        <div class="footer-content2">
          <ul>
            <li>实时动态</li>
            <li>班级相册</li>
            <li>活动直播</li>
            <li>幼儿考勤</li>
          </ul>
        </div>
        <div class="footer-content3">
          <ul>
            <li>成都市东方希望天祥天祥广场A座41层4102号</li>
            <li>028-85988298</li>
            <li>15982373521</li>
          </ul>
        </div>
        <div class="footer-content4">
          <ul>
            <li><img src="../assets/qrcode.png" /></li>
            <li class="qrcode">扫一扫，关注我们</li>
          </ul>
        </div>
      </div>
      <div class="footer-copyright">
        <p>
          Copyright © 2018 非络通达科技有限公司 All Rights Reserved.
          <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">川公网安备51010702043066号</a>
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            >蜀ICP备17037550号-6</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      chooseOne: "/",
    };
  },
  methods: {
    togo(val) {
      this.chooseOne = val;
      this.$router.push(val);
    },
    toAgent() {
      window.open("https://agent.jinshuy.com");
    },
    toSchool() {
      window.open("https://cms.jinshuy.com");
    },
  },
};
</script>
<style lang="less">
.homepage {
  .headerBox {
    position: fixed;
    top: 0;
    width: 100%;
    height: 68px;
    background: #ffffff;
    z-index: 9999;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .header {
    width: 1200px;
    margin: auto;
    height: 68px;
    background: #ffffff;
  }
  .chooseOne {
    text-align: center;
    position: relative;
    font-weight: 600;
  }
  .chooseOne::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 3px;
    background: #fbd646;
  }
  .h-image {
    float: left;
  }
  .h-image img {
    height: 46px;
    width: 142px;
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .menu {
    .rightBtn {
      cursor: pointer;
      margin-top: 19px;
      float: right;
      width: 115px;
      border-radius: 30px;
      height: 31px;
      line-height: 31px;
      text-align: center;
      background: #fbd646;
      color: #1e1e1e;
      font-size: 15px;
    }
  }
  .menu-list {
    margin: 0;
    height: 100%;
    margin-left: 247px;
    list-style-type: none;
  }
  .menu-list li {
    line-height: 10px;
    padding-top: 30px;
    cursor: pointer;
    //line-height: 68px;
    height: 100%;
    float: left;
    font-size: 15px;
    color: #1e1e1e;
    margin-right: 30px;
  }
  .footer-info {
    width: 1200px;
    padding-top: 90px;
    margin: auto;
    .footer-title {
      width: 1200px;
      padding-bottom: 13px;
      height: 25px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 24px;
      color: #1e1e1e;
      opacity: 1;
      border-bottom: 2px solid #ebedec;
    }
    .footer-title1 {
      margin-left: 5px;
      margin-right: 398px;
    }
    .footer-title2 {
      margin-right: 104px;
    }
    .footer-content {
      width: 1200px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 26px;
      color: #6e6e6e;
      opacity: 1;
      display: flex;
    }
    .footer-content1 {
      width: 386px;
      margin-left: 5px;
      margin-right: 65px;
      margin-bottom: 80px;
    }
    .footer-content2 {
      ul {
        list-style: none;
      }
      padding: 0px;
      margin-right: 75px;
    }
    .footer-content3 {
      ul {
        list-style: none;
      }
      padding: 0px;
      margin-right: 70px;
    }
    .footer-content4 {
      ul {
        list-style: none;
      }
      padding: 0px;
      .qrcode {
        margin-left: -10px;
        margin-top: -10px;
      }
    }
    .footer-copyright {
      margin-bottom: 15px;
      height: 21px;
      text-align: center;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #9d9d9d;
      opacity: 1;
      a {
        font-size: 14px;
        text-align: center;
        margin-left: 5px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        opacity: 1;
        color: #9d9d9d;
        text-decoration: none;
      }
      a:hover {
        color: black;
      }
    }
  }
}
</style>
